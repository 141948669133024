import React from "react";
import styled from "styled-components";
import BacklogItem from "./backlogitem";
import { Droppable } from "react-beautiful-dnd";

 
const BacklogList = styled.ul`
  min-height: 200px;
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-x: hidden;
    max-height: 275px;
    overflow-y: scroll;
    position: relative;
    background-color: ${(props) => (props.isDraggingOver ? "#ffe01b" : "rgb(254, 254, 254)")} }

`;
export default function Backlog(props) {
  const { items } = props;
  return (
      <Droppable droppableId={`backlog`} >
        {(provided,snapshot )=> (
          <BacklogList  
          {...provided.droppableProps} 
          ref={provided.innerRef} 
          isDraggingOver={snapshot.isDraggingOver }

          >
            {items.map((item, itemIndex) => (
              <BacklogItem itemIndex={itemIndex}  item={item} key={`key-backlog-${item}`} />
              
            ))}
            {provided.placeholder}
          </BacklogList>
        )}
      </Droppable>
  );
}
