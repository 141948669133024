import React, { useContext } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { GridContext }  from "./gridcontext";
import ReactGA from 'react-ga';


const ItemContainer = styled.li`
    list-style: none;
    margin: 5px 0 5px 0;
    width: calc(90% - 30px);
    padding: 5px 10px 5px 5px;
    display: flex;
    border: 2px solid #555;
    position: relative;
    border-radius: 5px;
    transition: width 0.5s linear;
    justify-content: ${(props) => (props.isList ? "flex-start"  : "space-between" )} }
    background-color: ${(props) => (props.isDragging ? "#ffe01b" : "#fff")} }
    transition: background-color 2s ease-out;
    box-shadow: 1px 3px 3px rgba(216,216,216,0.5);
`;

const Delete = styled.div`
  cursor: pointer;
  display: block;
  position: absolute;
  right: 10px;
`;

const Sectiontext = styled.span`
  margin: 5px;
  padding: 10px;
  background: rgb(250,250,250);
  border-bottom: ${(props) => (props.isList ? "none" : " solid 1px #999")} } ;
  mix-blend-mode: multiply;
  outline: none;
  background: ${(props) => (props.isList ? "rgb(256,256,256)" : "rgb(250,250,250)")} } ;

`;


const Checkbox = styled.div`
  cursor: pointer;
  display: ${(props) => (props.isList ? "block" : "none")} }
`;
 

export default function SectionItem(props) {
  const { index, itemIndex, item } = props;
  const context = useContext(GridContext);
  const isList = (context.mode === 'list')? true : false;


  const deleteTask = (e) => {

    const newState = context.state;
    newState.gridSections[index].items.splice(itemIndex, 1);
    context.dispatch({ type: "updateGrid", payload: newState });
    ReactGA.event({
      category: `Grid`,
      action: `Deleted task`,
      label: `${newState.gridSections[index].name}`
    });
  }

  const updateTask = (e) => {
    const updatedTask = e.currentTarget.innerText;
    const newState = context.state;
    newState.gridSections[index].items[itemIndex].name = updatedTask;
    context.dispatch({ type: "updateGrid", payload: newState });
    ReactGA.event({
      category: `Grid`,
      action: `Updated task`,
      label: `${newState.gridSections[index].name}`
    });
  }

  const completeTask = (e) => {
    const newState = context.state;
    const oldStatus = newState.gridSections[index].items[itemIndex].completed; 
    newState.gridSections[index].items[itemIndex].completed = !oldStatus;
    context.dispatch({ type: "updateGrid", payload: newState });
    ReactGA.event({
      category: `Grid`,
      action: `Completed task`,
      label: `${newState.gridSections[index].name}`
    });
  }


  return (
    <Draggable
      draggableId={`section-${index}-item-${itemIndex}`}
      
      index={itemIndex}
      key={`section-${index}-item-${itemIndex}`}
    >
      {(provided, snapshot) => (
        <ItemContainer
          isList={isList} 
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          id={`section-${index}-item-${itemIndex}`}
          key={`strategy-grid-item-list-item-${itemIndex}`}
          isDragging={snapshot.isDragging}
        >
         <Checkbox  isList={isList} className="material-icons" onClick={completeTask}> 
             { item.completed?   "check_box":"check_box_outline_blank" }
        </Checkbox>

          <Sectiontext  onBlur={updateTask} className="strategy-grid-text" 
          contentEditable={isList? "false" : "true"} 
          isList={isList} 

          suppressContentEditableWarning="true">
            {item.name}   
          </Sectiontext>
          <Delete className="material-icons"    isList={isList}  onClick={deleteTask}> close </Delete>
        </ItemContainer>
      )}
    </Draggable>
  );
}
