import React from "react";
import styled from "styled-components";
import SectionItem from "./sectionItem";
import { Droppable } from "react-beautiful-dnd";

const SectionContainer = styled.div`

  border: 3px solid #555;
  border-radius: 5px;
  // border-color: ${(props) => (props.actionable ? "rgba(95, 160, 82,0.5)" : "rgba(133,70,65,0.5)")} }
   
`;
const Sectionheader = styled.div`
  padding: 10px 0 10px 10px;
  font-size: 25px;
  font-weight: 500;
  color: #555;
  display: flex;
  flex-shrink: 1;
  align-items: flex-start;
  flex-direction: column;
  
`;
const Sectionlabel = styled.h2`
  margin:0;  
  font-size: 20px;
  font-weight: 800;
  color: #333;
  
   
`;
const Summarytext  = styled.p`
padding: 5px 5px;
background: #555;
border-radius:3px;
margin: 5px 10px 5px 0;
font-size: 11px;
color: rgb(256,256,256);
text-transform: uppercase;
`;

const Sectionlist = styled.ul`
  border-top: 3px solid #555;
  padding: 10px;
  background-color: ${(props) => (props.isDraggingOver ? "#ffe01b" : "transparent")} }

`;
export default function GridSection(props) {
  const { index, section } = props;
  const isActionable = (index > 1 )? false : true;
  return (
    <SectionContainer
      className="strategy-grid-item"
      data-section={index}
      key={`strategy-grid-item-${index}`}
      actionable={ isActionable }

    >
      <Sectionheader >
        <Sectionlabel >{section.name}</Sectionlabel>
        <Summarytext>{section.summary}</Summarytext>
      </Sectionheader>

      <Droppable droppableId={`section-${index}`}>

        {(provided,snapshot) => (
          <Sectionlist
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="strategy-grid-item-list"
            key={index}
            data-section={index}
            isDraggingOver={snapshot.isDraggingOver }
          >
            {section.items.map((item, itemIndex) => (
              <SectionItem
                itemIndex={itemIndex}
                index={index}
                item={item}
                key={`key-${item}`}
              />
            ))}
            {provided.placeholder}
          </Sectionlist>
        )}
      </Droppable>
 
    </SectionContainer>
  );
}
