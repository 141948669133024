import React, { useReducer, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import GridSection from "./components/GridSection";
import Backlog from "./components/Backlog";
import AddItem from "./components/addItem";
import { GridContext } from "./components/gridcontext";
import styled from "styled-components";
import "./App.css";


import ReactGA from "react-ga";

ReactGA.initialize("UA-4966942-4");
ReactGA.pageview(window.location.pathname + window.location.search);

const Dock = styled.section`
  top: 10px;
  position: sticky;
`;
const ControlContainer = styled.div`
  display:flex;
  justify-items: space-between;
  width: 100%;
  rgb(254, 254, 254);
 
`;
const Toggle = styled.a`
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 5px 10px 5px 5px;
  height: auto;
  cursor: pointer;
  margin: 0 0;

  & > span {
    font-size: 40px;
    margin-right: 10px;
    line-height: 1;
  }
`;
const Credit = styled.a`
  padding: 7px;
  color: #444;
  border: 1px solid #444;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  margin: 20px 0 10px 0;
  display: inline-block;
`;

function App() {
  let defaultState = {
    backlog: [],
    gridSections: [
      {
        name: "👍 Do",
        summary: "Important & Urgent",
        direction: "Handle now of the highest priority.",
        items: [],
      },
      {
        name: "📆 Plan",
        summary: "Important but Not Urgent",
        direction: "Need to be scheduled soon.",
        items: [],
      },
      {
        name: "📦 Hold",
        items: [],
        summary: "Not Important & Not Urgent",
        direction: "Take on when available.",
      },
      {
        name: "🤝 Delegate",
        items: [],
        summary: "Not Important but Urgent",
        direction:
          "Not as important as your other tasks but needs to be handled.",
      },
    ],
  };
  function resetData() {
    setMode("grid");
    dispatch({ type: "updateGrid", payload: defaultState });
    ReactGA.event({
      category: `Backlog`,
      action: `Reset`,
    });
  }
  function reducer(state, action) {
    switch (action.type) {
      case "updateSection":
        let sectionUpdate = state;
        sectionUpdate.gridSections[action.section].name = action.value;
        return { ...state, ...sectionUpdate };
      case "updateGrid":
        return { ...state, ...action.payload };
      case "updateItem":
        let updatedItem = state;
        updatedItem.gridSections[action.section].items[action.listItem] =
          action.value;
        return { ...state, ...updatedItem };
      default:
        return state;
    }
  }

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const oldSection = source.droppableId.replace("section-", "");
    const newSection = destination.droppableId.replace("section-", "");
    const newState = state,
      oldList =
        oldSection !== "backlog"
          ? newState.gridSections[oldSection].items
          : newState.backlog,
      transferText = oldList[source.index],
      newList =
        newSection !== "backlog"
          ? newState.gridSections[newSection].items
          : newState.backlog;

    oldList.splice(source.index, 1);
    newList.splice(destination.index, 0, transferText);
    dispatch({ type: "updateGrid", payload: newState });

    const dragMetric =
      newSection !== "backlog"
        ? {
            category: `Grid`,
            action: `Drag Drop`,
            label: newState.gridSections[newSection].name,
          }
        : {
            category: `Backlog`,
            action: `Drag Drop`, 
          };

    ReactGA.event(dragMetric);
  };

  const localState = localStorage.getItem("gridState");
  const localMode = localStorage.getItem("pgMode");
  const [state, dispatch] = useReducer(
    reducer,
    JSON.parse(localState) || defaultState
  );
  const [mode, setMode] = useState(JSON.parse(localMode) || "grid");

  useEffect(() => {
    localStorage.setItem("gridState", JSON.stringify(state));
  }, [state]);
  useEffect(() => {
    localStorage.setItem("pgMode", JSON.stringify(mode));
  }, [mode]);

  return (
    <GridContext.Provider value={{ state, dispatch, mode }}>
      <div id="strategy-container" className={mode}>
        <DragDropContext onDragEnd={onDragEnd}>
          <section className="strategy-masthead">
            <h1>Priority Grid</h1>
            <Dock>
              <AddItem />
              <Backlog items={state.backlog} />
            </Dock>

            <ControlContainer>
              {mode === "grid" && (
                <Toggle onClick={() => setMode("list")}>
                  {" "}
                  <span className="material-icons">toggle_off</span>List{" "}
                </Toggle>
              )}

              {mode === "list" && (
                <Toggle onClick={() => setMode("grid")}>
                  {" "}
                  <span className="material-icons">toggle_on</span>Grid
                </Toggle>
              )}
              <Toggle onClick={resetData}>
                {" "}
                <span className="material-icons">
                  settings_backup_restore
                </span>{" "}
                Clear
              </Toggle>
            </ControlContainer>

            <Credit href="http://stanwilsonjr.com/" target="_blank">
              Created by Stan Wilson Jr
            </Credit>
          </section>
          <section className="strategy-grid">
            {state.gridSections.map((section, index) => (
              <GridSection section={section} index={index} key={index} />
            ))}
          </section>
        </DragDropContext>
      </div>
    </GridContext.Provider>
  );
}

export default App;
