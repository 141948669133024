import React, { useContext } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { GridContext }  from "./gridcontext";
import ReactGA from 'react-ga';


const ItemContainer = styled.li`
   
    border: 2px solid #555;

    list-style: none;
    margin: 5px 0 5px 5px;
    width: calc(90% - 20px);
    padding: 5px 10px 5px 5px;
    border-radius: 5px;
    transition: background-color 0.5s ease;
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => (props.isDragging ? "#ffe01b" : "#fff")} }
    box-shadow: 1px 3px 3px rgba(216,216,216,0.5);
    & > span{
      margin: 5px;
      padding: 10px;
      background: rgb(250,250,250);
      border-bottom:  solid 1px #999;
      mix-blend-mode: multiply;
      outline: none;
    }
`;

const Delete = styled.div`
  cursor: pointer;
`;

export default function BacklogItem(props) {
  const { itemIndex, item } = props;
  const context = useContext(GridContext);
  // const isList = (context.mode === 'list')? true : false;


  const deleteTask = (e) => {

    const newState = context.state;

    newState.backlog.splice(itemIndex, 1);
    context.dispatch({ type: "updateGrid", payload: newState });
    ReactGA.event({
      category: `Backlog`,
      action: `Deleted task` 
    });
  }

  const updateTask = (e) => {

    const updatedTask = e.currentTarget.innerText;
    const newState = context.state;
    newState.backlog[itemIndex].name = updatedTask ;
    context.dispatch({ type: "updateGrid", payload: newState });
    ReactGA.event({
      category: `Backlog`,
      action: `Updated task` 
    });
  }

  return (
    <Draggable
      draggableId={`backlog-item-${itemIndex}`}
      index={itemIndex}
      key={`backlog-item-${itemIndex}`}
    >
      {(provided, snapshot) => (
        <ItemContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          id={`backlog-item-${itemIndex}`}
          data-item={itemIndex}
          mode={context.mode}
          key={`backlog-item-${itemIndex}`}
          isDragging={snapshot.isDragging}
        >
          <span
            className="strategy-grid-text"
            contentEditable="true"
            suppressContentEditableWarning="true"
            onBlur={  updateTask }
          >
            {item.name}
          </span>
          <Delete className="material-icons" onClick={deleteTask} > close </Delete>
        </ItemContainer>
      )}
    </Draggable>
  );
}
