import React, { useContext } from "react";
import styled from "styled-components";
import { GridContext }  from "./gridcontext";


const AddContainer = styled.div`
  margin: 30px 0 5px 0;
  display: flex;
  width: 100%;
  background: rgb(250, 250, 250);
  border-bottom: solid 1px #999;

`;
const AddText = styled.input`
  margin: 5px;
  padding: 5px;
  width: calc(100% - 60px);
  display: block;
  outline: none;
  font-size: 17px;
 
  background:transparent;
  border: 0;
`;
const Plus = styled.div`
  margin: 0;
  font-size: 30px;
  cursor: pointer;
  line-height: 1.5;
 `;
export default function AddItem(props) {
  let taskInput = React.createRef();
  const context = useContext(GridContext);

  const addTask = () => {

    if (taskInput.current.value === ''){
      return 
    }
    const newTask = taskInput.current.value;
    const newState = context.state;
    newState.backlog.splice(0, 0, {completed: false , name: newTask });
    context.dispatch({ type: "updateGrid", payload: newState });
    taskInput.current.value = "";
  };

  const pressReturn = event => {
    if (event.key === "Enter") {
      addTask()
    }
  }
    
  return (
    <AddContainer>
      <AddText
        ref={taskInput}
        placeholder="Add Tasks"
        onKeyPress={pressReturn}
      ></AddText>
      <Plus onClick={addTask} className="material-icons">
        add_box 
      </Plus>
    </AddContainer>
  );
}
